import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroMini from "../components/heroSmall"

const ServicesPage = () => (
  <Layout>
    <Seo title="Facilitations" />
    <HeroMini title="Facilitations"/>
    <main className="section has-text-black">
  <div className="container">
  <div className="columns">
  <div className="column">
    <p className="mb-6">Depend on our full-spectrum of consultation services to provide the ingenuity you need to solve your challenges on time and within budget.</p>
  </div>
  </div>

  {/* <div className="columns">
  <div className="column content">
  <p className="is-size-2">HTML</p>
  </div>
  </div> */}

  <div className="columns">
  <div className="column content has-text-centered">
  
    <p className="is-size-4 fusionfont">Project &amp; programme management</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/nolan/64/project.png"/>
    <p>Projects are achieved through people and processes, and Fusion are experts in project management as well as providing the tools, templates and training to build in-house stills and support project &amp; programme deliver. </p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Facilitation &amp; implementations</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/ios-filled/50/000000/full-tool-storage-box---v1.png"/>
    <p>Implementation depends on communication, coordination and collaboration and this is dependent on a shared understanding, outputs and outcomes. Fusion are experts at stakeholder management, communication and bringing groups together.</p>
  </div>

  <div className="column content has-text-centered">
    <p  className="is-size-4 fusionfont ">Business Analysis</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/nolan/64/business-report.png"/>
    <p>Fusion understands technology (data, processes and workflow) and business processes. From software to solutions, we understand, and support business and process change in finance, commercial, retail and public sector organisations. Our knowledge in lean, six-sigma, TQM, ToC, as well as our sector knowledge, results in process, performance and profit improvements for our clients.</p>
  </div>
</div>

<div className="columns">
  <div className="column content has-text-centered">
  
    <p className="is-size-4 fusionfont">User requirement and Scoping</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/fluent/48/000000/requirement.png"/>
    <p>Based on many years experience in finance, commercial, retail and public sector organisations we are able to work with your organisation to identify, define and document business, technology and user requirements essential for process, projects, business solutions and change. This helps in setting scope, managing expectations, outputs and outcomes.</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Change and restructure management</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/nolan/64/change.png"/>
    <p>Fusion has significant experience in business and organisational change. Our team includes accredited and qualified Management Consultants and Change Management Practitioners who are able to work with and support your staff, Senior Team, Leadership and Human Resources delivering restructure and change.</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont"></p>

  </div>
</div>

{/* 
<div className="columns">
  <div className="column content">
  <p className="is-size-2">React</p>
  </div>
  </div> */}


</div>
</main>
  </Layout>
)

export default ServicesPage
